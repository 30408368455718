<template>
  <div class="pt-24">
    <div class="rounded-md bg-green-50 p-4">
      <div class="flex">
        <div class="flex-shrink-0">
          <CheckCircleIcon class="h-5 w-5 text-green-400" aria-hidden="true" />
        </div>
        <div class="ml-3">
          <h3 class="text-sm font-medium text-green-800">
            Course is completed
          </h3>
          <div class="mt-2 text-sm text-green-700">
            <p>
              {{$store.state.auth.last_course_message}}
            </p>
          </div>
          <div class="mt-4">
            <div class="-mx-2 -my-1.5 flex">
              <router-link to="/" type="button" class="bg-green-50 px-2 py-1.5 rounded-md text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600">
                Go to homepage
              </router-link>
              <router-link to="/certificates" type="button" class="ml-3 bg-green-50 px-2 py-1.5 rounded-md text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600">
                List your certificates
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CheckCircleIcon } from '@heroicons/vue/solid'
import {mapActions} from 'vuex'

export default {
  components: {
    CheckCircleIcon,
  },
  methods: {
    ...mapActions('auth', ['fetchUser'])
  },
  mounted() {
    this.fetchUser()
  }
}
</script>